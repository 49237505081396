
import { Vue, Component, Prop } from "vue-property-decorator";
import Divider from "@/components/ewa/common/Divider.vue";

@Component({
  components: { Divider },
})
export default class Reports2AddTargetModal extends Vue {
  @Prop() action: (name: string, target: number) => boolean;

  targetName: string = null;
  target = 0;

  addTarget() {
    if (!this.targetName || !this.target) {
      return (window as any).error("Вы не заполнили поля");
    }

    if (this.target <= 0 || this.target > 100) {
      return (window as any).error("Цель должна быть в диапазоне 1 - 100");
    }

    const result = this.action(this.targetName, this.target);

    if (!result) {
      return (window as any).error("Цель с таким названием уже есть");
    }

    this.close();
  }

  close() {
    (this.$root as any).closeDialog();
  }
}
